
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import DTCls from "@/utils/datetime";
import { saveAs } from "@/api/request/base";
import Pagination from "@/comp/Pagination/index.vue";
import { getEventType, exportEventList, getEventList } from "@/api/request/behavior";

//组件
@Component({
  name: "UserAction",
  components: {
    Pagination,
  },
})
//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private typeOptions: any[] = [];
  private listLoading: boolean = true;

  //请求数据
  private listQuery: any = {
    //页码数据
    row: 20,
    page: 1,

    //临时数据
    time: [],

    //请求数据
    end_time: "",
    start_time: "",
    userid: undefined,
    event_type: undefined,

    //是否导出
    excel: false,
  };

  //时间参数
  get pickerOptions(): any {
    return {
      shortcuts: [
        {
          text: "今日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getTodayStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "昨日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getYesterdayStartTime()), new Date(DTCls.getYesterdayEndTime())]);
          },
        },
        {
          text: "本周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurWeekStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastWeekStartTime()), new Date(DTCls.getLastWeekEdnTime())]);
          },
        },
        {
          text: "本月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurMonthStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastMonthStartTime()), new Date(DTCls.getLastMonthEndTime())]);
          },
        },
        {
          text: "最近一周",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近三个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "全部",
          onClick(picker: any) {
            picker.$emit("pick", []);
          },
        },
      ],
      disabledDate(time: Date) {
        return time.getTime() > Date.now();
      },
    };
  }

  //创建
  created() {
    //初始化时间
    this.initTime();

    //获取操作列表
    this.getEventList();

    //获取数据
    this.getList();
  }

  //初始化时间
  private initTime(): void {
    this.listQuery.end_time = `${DTCls.getCurDateTimeYMD()}`;
    this.listQuery.start_time = `${DTCls.getCurDataTimeYMD30()}`;
    this.listQuery.time = [`${this.listQuery.start_time}`, `${this.listQuery.end_time}`];
    this.listQuery.start_time += "  00:00:00";
    this.listQuery.end_time += "  23:59:59";
  }

  //获取数据
  private async getList() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.excel = false;
    if (this.listQuery.time && this.listQuery.time.length == 2) {
      this.listQuery.start_time = `${this.listQuery.time[0]} 00:00:00`;
      this.listQuery.end_time = `${this.listQuery.time[1]} 23:59:59`;
    } else {
      this.listQuery.start_time = "";
      this.listQuery.end_time = "";
    }
    if (this.listQuery.userid) this.listQuery.userid = Number(this.listQuery.userid);

    //获取操作列表
    const { data } = await getEventList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //获取操作类型列表
  private async getEventList() {
    //显示等待
    this.listLoading = true;

    //获取数据
    const res = await getEventType();

    //数据赋值
    this.typeOptions = res.data;
  }

  //处理导出
  private async handleExcel() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.excel = true;
    if (this.listQuery.time && this.listQuery.time.length == 2) {
      this.listQuery.start_time = `${this.listQuery.time[0]} 00:00:00`;
      this.listQuery.end_time = `${this.listQuery.time[1]} 23:59:59`;
    } else {
      this.listQuery.start_time = "";
      this.listQuery.end_time = "";
    }
    if (this.listQuery.userid) this.listQuery.userid = Number(this.listQuery.userid);

    //获取数据
    const { data } = await exportEventList(this.listQuery);

    //保存数据
    this.listQuery.time && this.listQuery.time.length == 2 ? saveAs(data, `${this.listQuery.time[0]}--${this.listQuery.time[1]}操作记录`) : saveAs(data, "操作记录总览");

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取数据
    this.getList();
  }

  //处理重置
  private handleReset(): void {
    //初始化时间
    this.initTime();

    //数据赋值
    this.listQuery.nickname = "";
    this.listQuery.userid = undefined;
    this.listQuery.event_type = undefined;

    //获取数据
    this.getList();
  }
}
